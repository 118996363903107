import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SubscriptionAttributeService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/subscription';
    }


    getAttribute(subscriptionId) {
        let url = `${this.#api}/${subscriptionId}/attribute`
        return apiService.get(url)
    }
    
    saveAttribute(subscriptionId, data) {
        let url = `${this.#api}/${subscriptionId}/attribute`
        return apiService.post(url, data)
    }

    delete(subscriptionId, id) {
        let url = `${this.#api}/${subscriptionId}/attribute/${id}`
        return apiService.delete(url)
    }
}



