<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Subscription</h4>
                <div class="breadcrumb-sub-header">
                  <router-link :to="{name:'subscription'}">Dashboard</router-link>
                  \ Subscription
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-tabs vertical class="justify-start">
              <v-tab class="justify-start">
                Subscription
              </v-tab>
              <v-tab v-if="subscription.id" class="justify-start">
                Attributes
              </v-tab>
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field outlined dense v-model="subscription.title" label="Title"></v-text-field>
                        <span class="text text-danger" v-if="$v.subscription.title.$error">Title is required</span>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field outlined dense v-model="subscription.short_name"
                                      label="Short Name"></v-text-field>
                        <span class="text text-danger"
                              v-if="$v.subscription.short_name.$error">Short name is required</span>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field type="number" outlined dense v-model="subscription.price"
                                      label="Price"></v-text-field>
                        <span class="text text-danger"
                              v-if="$v.subscription.price.$error">Price must be a numeric value</span>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field type="number" outlined dense v-model="subscription.period"
                                      label="Period"></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-select v-model="subscription.period_unit" outlined dense :items="['month', 'year']"
                                  label="Period Unit"></v-select>
                      </v-col>
                      <v-col cols="12">
                        <label>Description</label>
                        <ckeditor :config="editorConfig"
                                  v-model="subscription.description"></ckeditor>

                      </v-col>
                      <v-col cols="2">
                        <v-switch v-model="subscription.offer_enabled" label="Offer Enabled"></v-switch>
                      </v-col>
                      <v-col cols="5" v-if="subscription.offer_enabled">
                        <v-text-field type="number" outlined dense v-model="subscription.offer_percentage"
                                      label="Offer Percentage"></v-text-field>
                      </v-col>
                      <v-col cols="5" v-if="subscription.offer_enabled">
                        <v-menu
                            ref="menuStartDate"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dates"
                                label="Offer Date"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="dates"
                              outlined
                              dense
                              range
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="2">
                        <v-switch @change="trialToggle()" v-model="subscription.is_trial" label="Is Trial"></v-switch>
                      </v-col>
                      <v-col cols="2">
                        <v-switch v-model="subscription.is_active" label="Status"></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn @click="close" text  class="mt-1" large
                          ><i class="fa fa-ban"></i> Cancel
                          </v-btn>
                          <v-btn
                              v-if="checkIsAccessible('subscription', 'create') || checkIsAccessible('subscription', 'edit')"
                              :loading="loading" @click="createOrUpdate('back')"
                                 type="button"
                                 class="btn btn-primary mt-1 ml-2 text-white"
                          ><i class="fa fa-save"></i> Save
                          </v-btn>
                          <v-btn :loading="loading" @click="createOrUpdate('continue')"
                                 type="submit"
                                 class="btn btn-primary mt-1 ml-2 text-white"
                          ><i class="fa fa-save"></i> Save And Continue
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item v-if="subscription.id">
                <attribute :subscription_id="subscription.id"></attribute>
              </v-tab-item>
            </v-tabs>


          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import SubscriptionService from "@/services/subscription/SubscriptionService";
import Attribute from "@/view/pages/view/subscription/Attribute";
import {required, numeric} from "vuelidate/lib/validators";

const subscriptionService = new SubscriptionService();

export default {
  name: "CreateAndUpdate",
  components: {
    Attribute
  },
  validations: {
    subscription: {
      "title": {required},
      "short_name": {required},
      "price": {numeric}
    }
  },
  data() {
    return {
      loading: false,
      dates: [],
      menu: false,
      edit: false,
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      subscription: {
        title: null,
        short_name: null,
        description: null,
        price: 0,
        period: "12",
        period_unit: "month",
        offer_enabled: null,
        offer_start_date: null,
        offer_end_date: null,
        formatted_offer_start_date: null,
        formatted_offer_end_date: null,
        is_active: true,
        is_trial: false,
        offer_percentage: '',
      }
    }
  },
  computed: {
    subscription_id() {
      return this.$route.params.id
    }
  },
  mounted() {
    if (this.subscription_id)
      this.getSubscription()
  },
  methods: {
    close() {
      this.$tabs.close().then(() => {
        this.$tabs.open({name:'subscription'});
      })
    },

    getSubscription() {
      subscriptionService.show(this.subscription_id).then(response => {
        this.subscription = response.data.subscription;
        if (this.subscription.offer_enabled) {
          this.dates[0] = this.subscription.offer_start_date ? this.subscription.offer_start_date : null;
          this.dates[1] = this.subscription.offer_end_date ? this.subscription.offer_end_date : null;
        }
        this.edit = true
      }).catch(error => {
        this.loading = false
      }).finally(this.loading = false)
    },
    createOrUpdate(type) {
      this.$v.subscription.$touch();
      if (this.$v.subscription.$error) {
        setTimeout(() => {
          this.$v.subscription.$reset();
        }, 3000)
      } else {
        if (this.subscription.offer_enabled) {
          this.subscription.offer_start_date = this.dates.length > 0 ? this.dates[0] : null
          this.subscription.offer_end_date = this.dates.length > 0 ? this.dates[1] : null
        }
        if (this.edit)
          this.update(type)
        else
          this.create(type)
      }
    },
    update(type) {
      subscriptionService.update(this.subscription.id, this.subscription).then(response => {
        this.$snotify.success('Subscription updated successfully')
        this.resetForm(type, this.subscription.id)
      }).catch(error => {
        this.loading = false
        this.$snotify.error('Something went wrong. Please try again later')
      }).finally(this.loading = false)
    },
    create(type) {
      subscriptionService.create(this.subscription).then(response => {
        this.$snotify.success('Subscription created successfully')
        this.resetForm(type, response.data.subscription.id)
      }).catch(error => {
        this.loading = false
        this.$snotify.error('Something went wrong. Please try again later')
      }).finally(this.loading = false)
    },
    resetForm(type, id = null) {
      this.subscription = {
        "title": null,
        "short_name": null,
        "price": 0,
        "period": "12",
        "period_unit": "month",
      }
      switch (type) {
        case 'back':
          this.$router.push({name: "subscription"})
          break;
        case 'continue':
          if (this.subscription_id)
            this.getSubscription();
          else
            this.$router.push({name: "subscription-update", params: {id: id}})
          break;
      }
    },
    trialToggle() {
      if (this.subscription.is_trial) {
        this.subscription.price = 0;
      }
    }
  }
}
</script>

<style scoped>

</style>
