<template>
  <v-card>
    <v-card-text>
      <div  v-if="store_attributes && store_attributes.length>0">
      <table class="table">
        <thead>
        <th>Title</th>
        <th class="text-center">Availability</th>
        <th>Action</th>
        </thead>
        <tbody v-if="store_attributes && store_attributes.length>0">
        <tr v-for="(attribute, index) of store_attributes" :key="index">
          <td>{{ attribute.title }}</td>
          <td class="text-center"><i class="fas"
                                     :class="attribute.is_available? 'fa-check text-success': 'fa-ban text-danger' "></i>
          </td>
          <td class="">
            <i @click="deleteAttribute(attribute.id)" class="fas fa-trash text-danger"></i>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="50">No data found</td>
        </tr>
        </tbody>
      </table>

      </div>
      <h4 class="mt-3">Add New Attribute</h4>
      <v-divider></v-divider>
      <v-row v-for="(attribute, index) of attributes" :key="index">
        <v-col cols="5">
          <v-text-field label="Attribute Title" outlined dense v-model="attribute.title"
                        :error="$v.attributes.$each[index].title.$error"
          ></v-text-field>
          <span class="text-danger" v-if="$v.attributes.$each[index].title.$error">Title is required </span>
        </v-col>
        <v-col cols="3">
          <v-switch label="status" v-model="attribute.is_available"></v-switch>
        </v-col>
        <v-col cols="4">
          <button v-if="index < attributes.length" class="btn btn-secondary" @click="removeAttribute(index)"><i
              class="fas fa-trash"></i></button>
          <button v-if="index === (attributes.length-1)" class="ml-1 btn btn-primary" @click="addAttribute"><i
              class="fas fa-plus"></i></button>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-right">
            <router-link :to="{name:'subscription'}" color="red"
                         type="button"
                         class="btn btn-secondary mt-1 "
            ><i class="fa fa-ban"></i> Cancel
            </router-link>
            <v-btn @click="createAttribute" :loading="loading"
                   type="submit"
                   class="btn btn-primary mt-1 ml-2 text-white"
            ><i class="fa fa-save"></i> Save
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SubscriptionAttributeService from "@/services/subscription/attribute/SubscriptionAttributeService";
import {required} from "vuelidate/lib/validators";

const attributeService = new SubscriptionAttributeService();
export default {
  name: "Attribute",
  props: ["subscription_id"],
  validations: {
    attributes: {
      $each: {
        title: {required},
      }
    }
  },

  data() {
    return {
      loading:false,
      store_attributes: [],
      attributes: [
        {
          title: null,
          is_available: null
        }
      ]
    }
  },
  mounted() {
    this.getAttributes();
  },

  methods: {
    getAttributes() {
      attributeService.getAttribute(this.subscription_id).then(response => {
        if (response.data.status != "ERROR")
          this.store_attributes = response.data.attributes
      })
    },
    addAttribute() {
      this.attributes.push(
          {
            title: null,
            is_available: null
          }
      )
    }, removeAttribute(index) {
      this.attributes.splice(index, 1)
    },
    createAttribute() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000)
      } else {
        this.loading = true
        attributeService.saveAttribute(this.subscription_id, this.attributes).then(response => {
          this.loading = true
          this.$snotify.success("Attribute created successfully")
          this.getAttributes();
        }).catch(error => {
          this.loading = false
          this.$snotify.error('Something went wrong. Please try again later')
        }).finally(()=>{
          this.loading = false
          this.attributes = [];
          this.addAttribute();
          this.$v.$reset();
        })
      }
    },
    deleteAttribute(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            attributeService.delete(this.subscription_id, id).then(response => {
              this.$snotify.success('Attribute delete successfully')
            }).catch(error => {
              this.$snotify.error('Something went wrong. Please try again later')
              this.loading = false;
            }).finally(()=>{
              this.loading = false
              this.getAttributes();
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>